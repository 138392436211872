import FooterBasePage from "../basepages/footer-basepage/FooterBasePage";
import { Table } from "react-bootstrap";

const CookiesPage = () => {
  return (
    <FooterBasePage
      pageTitle="Cookies"
      pageSubTitle="Protecting and managing your online privacy."
    >
      <div className="bg-white my-0 my-sm-4 main-container">
        <p className="fw-bold">Last updated February 2025</p>
        <p>
          Our Cookie Policy explains how we use cookies and similar tracking
          technologies. It also explains the choices you can make about whether
          we can put some of these cookies on your computer, tablet or mobile
          device.
        </p>
        <p>
          In this policy, where we say 'cookies' it can also mean similar
          tracking technologies. Similar tracking technologies are those that
          collect data while you use our websites and mobile apps, and when you
          receive, open or respond to emails we send you. You can find more
          information on the tracking we use in our emails, including how you
          can stop it, in the Email Tracking section.
        </p>
        <p>
          Data we collect will be held by Lex Autolease Limited, which is part
          of Lloyds Banking Group. We use this data to:
        </p>
        <ul>
          <li>Protect you from fraud and keep improving security.</li>
          <li>
            Study how people use our websites and apps and our other services.
            This helps us to improve them.
          </li>
          <li>
            Decide which of our products, services and offers may be relevant
            for you.
          </li>
          <li>
            Tailor the marketing you see on social media, apps and other
            websites.
          </li>
        </ul>
        <p>
          We may share this data with other companies in the Group. We also
          share some data with outside organisations. Some of them may place
          their own cookies on your device when you visit our website. You can
          find out more about how we share data - and who we share it with - in
          our {""}
          <a href="/privacy" className="border-bottom border-white">
            Privacy Notice.
          </a>
        </p>
        <p>
          We do not sell the personal information we have about you to outside
          organisations.
        </p>
        <h2 className="h3">Understanding Cookies</h2>
        <p>
          Cookies are small files that are sent to your computer, tablet or
          mobile device by websites when you visit them. They stay on your
          device, so that when you visit the website again, it knows that you've
          been there before and can give you a better experience. Cookies store
          information about your visits to that website. This information could
          be your choices, the type of device you used to connect to it, where
          you were when you connected, and the pages you visited on the site.
        </p>
        <h2 className="h3">Multiple users</h2>
        <p>
          If more than one person uses your device, the choices set up by other
          people will apply to you as well unless you change them. For example,
          if you share a computer with your family, you may see ads based on
          sites they have visited as well as sites you have visited.
        </p>
        <h2 className="h3">Session and Persistent Cookies</h2>
        <p>All cookies are either session cookies or persistent cookies.</p>
        <p>
          <span className="fw-bold">Session cookies</span> last for the length
          of your visit to a website. They delete themselves when you close your
          browser.
        </p>
        <p>
          <span className="fw-bold">Session cookies</span> can help with
          security. For instance, they can keep you logged on as you move around
          a website.
        </p>
        <p>
          <span className="fw-bold">Persistent cookies </span>
          stay on your device when you close your browser.
        </p>
        <p>
          <span className="fw-bold">Persistent cookies </span>are used for many
          jobs. For instance, they can remember your User ID for websites where
          you log on. They can also store your choices for when you go back to a
          website.
        </p>
        <p>
          Organisations find them useful to see how people use their websites.
          We may also use them to tailor the ads and marketing you see on social
          media, apps and other websites.
        </p>
        <h2 className="h3">First and Third-Party cookies</h2>
        <p>
          Whether a cookie is first or third-party depends on where it comes
          from.
        </p>
        <p>
          <span className="fw-bold">First-party cookies </span> are set by
          websites you go to.
        </p>
        <p>
          <span className="fw-bold">Third-party cookies </span> are set by
          outside organisations such as social media, search engines, other
          advertising networks and our business partners.
        </p>
        <p className="fw-bold">Our Third-Party Partners</p>
        <p>
          If you have given us your consent, we may allow third parties to
          collect your data by setting their own cookies on your device. If you
          use our Mobile Banking app, they may also use mobile device
          identifiers to personalise and measure adverts shown to you.
        </p>
        <p>
          You can change your choices at any time. Select the 'Cookies' link at
          the bottom of any page of our website and go from there.
        </p>
        <p>
          If you use one of our mobile apps, you can also change how your mobile
          device identifier is used. You can do that by updating your settings
          in the app.
        </p>
        <p>
          Here are links to the information notices for our main Third-Party
          Partners.
        </p>
        <Table responsive="md" bordered>
          <tbody>
            <tr>
              <td valign="middle">Google</td>
              <td>
                <p>
                  <a
                    href="https://policies.google.com/technologies/ads"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    How Google use cookies in advertising
                  </a>
                </p>
                <p>
                  <a
                    href="https://policies.google.com/technologies/partner-sites"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    How Google uses the data it collects
                  </a>
                </p>
                <p>
                  <a
                    href="https://policies.google.com/technologies/types"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Types of cookies we use with Google
                  </a>
                </p>
              </td>
            </tr>
            <tr>
              <td valign="middle">Oracle Cloud</td>
              <td>
                <p>
                  <a
                    href="https://www.oracle.com/uk/legal/privacy/privacy-policy.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Oracle Data Cloud Privacy Policy
                  </a>
                </p>
                <p>
                  <a
                    href="https://datacloudoptout.oracle.com/#optout"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Opt-out from Oracle cookies
                  </a>
                </p>
              </td>
            </tr>
            <tr>
              <td valign="middle">Meta</td>
              <td>
                <p>
                  <a
                    href="https://www.facebook.com/policy/cookies"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Meta Cookies Policy
                  </a>
                </p>
                <p>
                  <a
                    href="https://www.facebook.com/privacy/policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Meta Privacy Policy
                  </a>
                </p>
              </td>
            </tr>
            <tr>
              <td valign="middle">Microsoft Advertising (Bing/Yahoo)</td>
              <td>
                <p>
                  <a
                    href="https://about.ads.microsoft.com/en-us/policies/legal-privacy-and-security"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Legal, privacy and security policies - Microsoft Advertising
                  </a>
                </p>
                <p>
                  <a
                    href="https://privacy.microsoft.com/en-us/privacystatement"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Microsoft Privacy Statement - Microsoft privacy
                  </a>
                </p>
              </td>
            </tr>
            <tr>
              <td>LinkedIn</td>
              <td>
                <a
                  href="https://www.linkedin.com/legal/cookie-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LinkedIn Cookie Policy
                </a>
              </td>
            </tr>
          </tbody>
        </Table>
        <p className="fw-bold">Cookies with more than one use</p>
        <p>
          Some of our cookies collect data for more than one use. We will only
          use these cookies for their essential purposes unless you have given
          your consent to any other uses they have.
        </p>
        <p className="fw-bold">Cookies that are already on your device</p>
        <p>
          Turning off one or more types of cookies won't delete any that have
          been downloaded in the past. We may still use data we collected up to
          that point, but will stop collecting new data.
        </p>
        <p className="fw-bold">Managing cookies choices in your browser</p>
        <p>
          You can turn off or delete cookies in your browser. If you do this, it
          may affect sites that use similar cookies to us.
        </p>
        <p>
          Cookies choices you set in your browser replace any you set on a
          single website. For instance, if you run an ad blocker on your
          browser, you won't see ads that are tailored to you. This will still
          be true, even if you have turned on marketing cookies.
        </p>
        <p>
          Find out how to manage cookies in common browsers (Internet Explorer,
          Chrome, Firefox and Safari) on the{" "}
          <a
            href="https://ico.org.uk/"
            target="_blank"
            rel="noopener noreferrer"
            className="border-bottom border-white"
          >
            Information Commissioners' Office (ICO) website.
          </a>
        </p>
        <h2 className="h3">Email Tracking or similar tracking technologies</h2>
        <p>
          This section explains more about the technologies we use to review how
          you interact with emails we send you. It explains why we do this, and
          how you can stop it.
        </p>
        <p>
          We review how you interact with emails we send you by using small
          images that you cannot see called 'pixels' within our emails. These
          pixels allow us to capture some information about emails we've sent
          you, such as:
        </p>
        <ul className="ms-4">
          <li>When you opened the email, </li>
          <li>How many times you opened it, and </li>
          <li>The device you used to open it.</li>
        </ul>
        <p>
          This is called 'email tracking'. We use this information to help us
          understand how effective our emails are, and to improve our
          communications with you. The pixel stays in the email, but leaves
          nothing else on your device.
        </p>
        <p className="fw-bold">How to stop Email Tracking</p>
        <p>You can stop this by:</p>
        <ul className="ms-4">
          <li>Closing the email before you download any images.</li>
          <li>
            Setting your browser or email program to restrict or block our
            emails.
            <ul className="ms-4">
              <li>
                Sometimes your browser or email program setting will
                automatically download images. For more details on this, you'll
                need to read the instructions for your browser, email program or
                device.
              </li>
            </ul>
          </li>
        </ul>
        <p className="fw-bold">
          How to stop Email Tracking for marketing purposes
        </p>
        <p>You can stop this by:</p>
        <ul className="ms-4">
          <li>
            Opting out from marketing emails by reviewing your marketing
            choices.
            <ul className="ms-4">
              <li>
                You can review your marketing choices in a few ways. Use your
                internet or mobile banking applications, visit one of our
                branches, or see the 'How to contact us' section in our Data
                Privacy Notice to speak to us about this.
              </li>
            </ul>
          </li>
        </ul>
        <p className="fw-bold">Email Link Tracking</p>
        <p>
          When we send you emails including links to our web pages, we track the
          Lloyds Banking Group web pages you visit when using those links. We'll
          only do this if you accept Marketing Cookies when you enter our site.
          We do not track any other non-Lloyds Banking Group sites you may also
          visit.
        </p>
        <p className="fw-bold">How to stop Email Link Tracking</p>
        <p>
          You can stop this anytime by visiting our Cookie Policy and rejecting
          Marketing Cookies.
        </p>
      </div>
    </FooterBasePage>
  );
};

export default CookiesPage;
