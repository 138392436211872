import { setLocalStorage, getLocalStorage } from "../../storage/LocalStorage";

const supportedBrands = [
  "blackhorse",
  "gwmora",
  "xpeng",
  "halifax",
  "isuzu",
  "jaguar",
  "landrover",
  "shogun",
  "suzuki",
  "default",
];

const getDefaultBrand = () => {
  return "default";
};

const isSupportedBrand = (brand) => {
  let brandExist = false;
  if (brand && typeof brand === "string") {
    if (supportedBrands.includes(brand.toLocaleLowerCase().trim())) {
      brandExist = true;
    }
  }
  return brandExist;
};

const saveBrand = (brand) => {
  if (brand && typeof brand === "string") {
    if (isSupportedBrand(brand)) {
      const brandToSave = brand.toLowerCase().trim();
      try {
        setLocalStorage("brandName", brandToSave);
        return true;
      } catch (e) {
        console.log("Error in saving brand value to local storage");
      }
    }
  }
  return false;
};

const getSavedBrand = () => {
  let brandName = getDefaultBrand();
  try {
    const localStorageBrandName = getLocalStorage("brandName");
    if (isSupportedBrand(localStorageBrandName)) {
      brandName = localStorageBrandName.toLowerCase().trim();
    }
  } catch (e) {
    console.log("Unable to read brand name from local storage");
  }
  return brandName;
};

export {
  supportedBrands,
  isSupportedBrand,
  saveBrand,
  getSavedBrand,
  getDefaultBrand,
};
